const React = require('react');
const classNames = require('classnames');
const { useRenderContext } = require('../pages/home/context');

const withFollowerData = (WrappedComponent) => {
  const FollowerData = (props) => {
    const { marketplaceInfo, device, appearance, osName } = useRenderContext();
    const { buyerId, ownerId, homeURL, isWebview, siteId, d2id } = marketplaceInfo;

    const hasDynamicCarousels = appearance.getComponentByName('DynamicCarousels');

    const newProps = {
      ...props,
      ownerId,
      buyerId,
      baseURL: `${homeURL}/api`,
      isWebview,
      siteId,
      d2id,
    };

    if (hasDynamicCarousels) {
      const withoutFollowerProps = {
        ...newProps,
        followers: false,
      };

      const iosEshopHeaderClass = classNames(
        { 'eshops-header--ios': isWebview && (!osName || osName?.toLowerCase() === 'ios') },
      );

      if (device === 'mobile') {
        return (
          <div className={`remove-shadow-container ${iosEshopHeaderClass}`}>
            <WrappedComponent
              {...withoutFollowerProps}
            />
          </div>
        );
      }

      return (
        <WrappedComponent
          {...withoutFollowerProps}
        />
      );
    }

    return (
      <WrappedComponent
        {...newProps}
      />
    );
  };
  return FollowerData;
};

module.exports = withFollowerData;
