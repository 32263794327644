import React from 'react';

import BottomSheetContext from '../appearance/bottomSheetMenu/context/BottomSheetContext';

const { useContext } = React;

const withBottomSheetContext = (Component) => {
  const ComponentWithBottomSheetContext = (props) => {
    const { setUrl, setIsOpen, isOpen, url } = useContext(BottomSheetContext);
    const newProps = {
      ...props,
      setUrl,
      setIsOpen,
      isOpen,
      url,
    };
    return (
      <Component
        {...newProps}
      />
    );
  };

  return ComponentWithBottomSheetContext;
};

export default withBottomSheetContext;
