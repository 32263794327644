const React = require('react');
const CategorySuper = require('@mshops-components-library/category-super').default;

const { useRenderContext } = require('../../pages/home/context');
const CategoryLabelImage = require('../../components/CategoryLabelImage').default;
const { WITH_CATEGORY_SUPER } = require('../../utils/constants/shop/types');

const CategoryItem = props => {
  const { shopType } = useRenderContext();

  return WITH_CATEGORY_SUPER.includes(shopType) ? (
    <CategorySuper {...props} />
  ) : (
    <CategoryLabelImage {...props} />
  );
};

module.exports = CategoryItem;
