import React from 'react';
import PropTypes from 'prop-types';

import { Card, PolycardContext } from '@polycard/card';

import { useRenderContext } from '../pages/home/context';

const Polycard = ({ item, device, cta, metrics }) => {
  const { marketplaceInfo } = useRenderContext();
  const { siteId } = marketplaceInfo;
  const newContextValue = { ...item?.result?.polycard_context, type: device === 'desktop' ? 'list-card' : 'grid-card' };
  const trackingId = metrics?.tracking_id;
  const notMLM = siteId !== 'MLM';
  const polycard = item?.result?.polycards?.length && item.result.polycards[0];

  const {
    url = '',
    url_fragments: urlFragments = '',
  } = polycard?.metadata || {};
  const newUrlFragments = urlFragments.replace('%7Btracking_id%7D', trackingId);
  const urlLink = `https://${url}${newUrlFragments}`;
  const deeplink = `meli://${url}?forceInApp=true${newUrlFragments}`;

  const actionLinkComponent = {
    type: 'action_links',
    action_links: [
      {
        id: 'add_action',
        text: cta,
        url: device === 'native' ? deeplink : urlLink,
      },
    ],
    column: 1,
  };

  return (
    <div className="ui-ms-polycard-container">
      <PolycardContext
        contextValue={newContextValue}
        deviceType="desktop"
        withoutIcons
        thousandSeparator={notMLM ? '.' : ','}
        decimalSeparator={notMLM ? ',' : '.'}
      >
        {item?.result?.polycards?.map((i) => (
          <Card
            key={i.metadata.id}
            polycard={
              {
                ...i,
                components: [...i.components, actionLinkComponent],
                metadata: {
                  ...i.metadata,
                  url_fragments: newUrlFragments,
                },
              }
            }
          />
        ))}
      </PolycardContext>
    </div>
  );
};

Polycard.propTypes = {
  item: PropTypes.shape({
    result: PropTypes.shape({
      polycard_context: PropTypes.shape({
        contract_version: PropTypes.string,
        picture_template: PropTypes.string,
        picture_size_default: PropTypes.string,
        url_prefix: PropTypes.string,
        type: PropTypes.string,
        picture_square_default: PropTypes.string,
        url_fragments_default: PropTypes.string,
        url_params_default: PropTypes.string,
      }),
      polycards: PropTypes.arrayOf(PropTypes.shape({
        metadata: PropTypes.shape({}),
        pictures: PropTypes.shape({}),
        components: PropTypes.arrayOf(PropTypes.shape({})),
      })),
    }),
  }).isRequired,
  device: PropTypes.string.isRequired,
  cta: PropTypes.string.isRequired,
  metrics: PropTypes.shape({
    tracking_id: PropTypes.string,
    matt_tool: PropTypes.string,
  }).isRequired,
};

export default Polycard;
