/**
 * startApp dependency
 */
const startMarketplaceApp = require('../startMarketplaceApp');

/**
 * Main component
 */
const MarketplaceView = require('../../pages/marketplaceHome/components/marketplaceView');

startMarketplaceApp(MarketplaceView);
