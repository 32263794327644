const React = require('react');
const MelidataTrack = require('nordic/melidata/melidata-track');
const { useRenderContext } = require('../pages/home/context');

const withHandleMenu = (WrappedComponent) => {
  const HandleMenu = (props) => {
    const { marketplaceInfo, osName } = useRenderContext();
    const { isWebview, labelURL, ownerId, shopType } = marketplaceInfo;

    const handleMenu = () => {
      window.location.href = labelURL;
    };

    const newProps = {
      ...props,
      osName,
      isWebview,
      handleMenu,
    };

    return (
      <>
        <WrappedComponent
          {...newProps}
        />
        <MelidataTrack
          path="/eshops/menu"
          event_data={{
            owner_id: ownerId.toString(),
            shop_type: shopType.toLowerCase(),
          }}
        />,
      </>
    );
  };
  return HandleMenu;
};

module.exports = withHandleMenu;
