/* eslint-disable arrow-body-style */
const React = require('react');
const { useRenderContext } = require('../pages/home/context');

const { WITHOUT_ACTIONS_IN_ESHOPSITEM } = require('../utils/constants/shop/types');

const withFreeCarousel = (WrappedComponent) => {
  const FreeCarouselData = (props) => {
    const { shopType } = useRenderContext();
    const seeElements = {
      action_card: !WITHOUT_ACTIONS_IN_ESHOPSITEM.includes(shopType),
    };
    return (
      <WrappedComponent
        {...props}
        useChevron={false}
        seeElements={seeElements}
        isFreeCarousel={shopType !== 'super' && shopType !== 'super_partner'}
      />
    );
  };
  return FreeCarouselData;
};

module.exports = withFreeCarousel;
