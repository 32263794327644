import React from 'react';
import PropTypes from 'prop-types';
import PlayIcon from '@mshops-web-components/icons/PlayIcon';

const PlayIconVideo = ({ onImageClick, i18n = { gettext: f => f } }) => {
  const buttonLabel = i18n.gettext('Ver video');

  return (
    <div className="play-wrapper" onClick={onImageClick} aria-hidden="true">
      <PlayIcon className="play-icon" />
      <p className="play-text">{buttonLabel}</p>
    </div>
  );
};

PlayIconVideo.propTypes = {
  i18n: PropTypes.shape(),
  onImageClick: PropTypes.func.isRequired,
};

export default PlayIconVideo;
