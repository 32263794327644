import React from 'react';
import PropTypes from 'prop-types';
import PlayIconCaptive from './captive';
import PlayIconExplorer from './explorer';
import PlayIconDefault from './default';

const PLAY_ICON_BY_THEME = {
  captive: PlayIconCaptive,
  explorer: PlayIconExplorer,
};

const PlayIcon = ({ onImageClick, theme }) => {
  const PlayIconComponent = PLAY_ICON_BY_THEME[theme] || PlayIconDefault;

  return <PlayIconComponent onImageClick={onImageClick} />;
};

PlayIcon.propTypes = {
  onImageClick: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
};

export default PlayIcon;
