const React = require('react');

const { ESHOP } = require('../utils/constants/sections');

function withMarketplaceInfo(WrappedComponent) {
  const MarketplaceInfo = (props) => (
    <WrappedComponent
      {...props}
      layoutTheme={ESHOP}
    />
  );
  return MarketplaceInfo;
}

module.exports = withMarketplaceInfo;
