const React = require('react');

const { useCartContext, useCartDispatcher } = require('../../context/cart');
const { useFreeShippingDispatcher } = require('../../context/freeShipping');
const { useRenderDispatcher } = require('../../../home/context');

const { useEffect } = React;

const CartSubscription = () => {
  const { marketplaceInfo: { userZipCode, shopType } } = useCartContext();
  const cartActions = useCartDispatcher();
  const freeShippingActions = useFreeShippingDispatcher();
  const renderActions = useRenderDispatcher();

  const updateCartItems = async () => {
    renderActions.setLoading(true);
    try {
      await Promise.all([
        cartActions.getCartItems(shopType),
        freeShippingActions.updateFreeShippingBar(userZipCode, shopType),
      ]);
    } catch (error) {
      window.MobileWebKit.navigation.reload();
    } finally {
      renderActions.setLoading(false);
    }
  };

  useEffect(() => {
    if (window.MobileWebKit) {
      const subscriptionId = window.MobileWebKit.lifecycle.onViewAppeared(updateCartItems);

      return () => window.MobileWebKit.lifecycle.unsubscribe(subscriptionId);
    }

    return undefined;
  }, []);

  return null;
};

module.exports = CartSubscription;
