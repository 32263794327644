module.exports = {
  POLYCARD_ICONS: [
    'poly_full',
    'poly_meli',
    'poly_star',
    'poly_star_fill',
    'poly_star_half',
    'poly_coupon',
    'poly_warning',
    'poly_cbt',
    'poly_cockade',
  ],
};
